import { useState } from "react";

const Login = ({ setLoginAuth }) => {
  // The correct 4-digit pin (stored in the frontend)
  const correctPins = ["6909","1396"];

  // Holds the current entered pin
  const [enteredPin, setEnteredPin] = useState("");
  const [error, setError] = useState(false);
  const [titleState, setTitleState] = useState("");

  // Function to handle pin entry
  const handlePinClick = (num) => {
    if (enteredPin.length < 4) {
      setEnteredPin(enteredPin + num);
      setError(false);
    }
  };

  // Function to handle pin submission
  const handlePinSubmit = () => {
    setTitleState('waiting');
    if (correctPins.includes(enteredPin)) {
      setLoginAuth('success');
      setTitleState('');
    } else {
      setTitleState('');
      setError(true);
      setEnteredPin("");
      document.getElementById('login-wrapper').classList.add("denied");
    }
  };

  // Function to clear pin
  const handleClear = () => {
    setEnteredPin("");
    setError(false);
  };

  // Function to delete last digit of pin
  const handleDelete = () => {
    setEnteredPin(enteredPin.slice(0, -1));
    setError(false);
  };

  // A simple Title Splitter
  const TitleSplitter = () => {
    if (titleState === "") {
      return <h1>LOGIN</h1>;
    } else if (titleState === "waiting") {
      return <h1>CONFIRMING...</h1>;
    } else {
      return <div>PANIC</div>;
    }
  };

  // Pin pad component UI
  return (
    <div className="tab-wrapper login">
      <div id="login-wrapper">
        <TitleSplitter />
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ fontSize: '2rem', margin: '20px', color: error ? 'red' : 'black' }}>
            {enteredPin.padEnd(4, "-")}
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 60px)', gap: '10px' }}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
              <button
                key={num}
                onClick={() => handlePinClick(num)}
                style={{ width: '60px', height: '60px', fontSize: '1.5rem', cursor: 'pointer' }}
              >
                {num}
              </button>
            ))}
            <button onClick={handleClear} style={{ gridColumn: 'span 1', height: '60px', cursor: 'pointer' }}>Clear</button>
            {[0].map((num) => (
              <button
                key={num}
                onClick={() => handlePinClick(num)}
                style={{ width: '60px', height: '60px', fontSize: '1.5rem', cursor: 'pointer' }}
              >
                {num}
              </button>
            ))}
            <button onClick={handleDelete} style={{ height: '60px', cursor: 'pointer' }}>Del</button>
          </div>
          <button
            onClick={handlePinSubmit}
            style={{ marginTop: '20px', padding: '10px 20px', fontSize: '1.2rem', cursor: 'pointer' }}
          >
            Submit
          </button>
          {error && <div style={{ color: 'red', marginTop: '10px' }}>Incorrect PIN. Try again.</div>}
          <div className="login-description">Can't log in? Contact Seb.</div>
        </div>
      </div>
    </div>
  );
};

export default Login;
