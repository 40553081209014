import {useState,useEffect,useRef} from 'react'
import DocsData from './DocsData'
import AIDSelect from '../MainComponents/AIDSelect'
import {BiCheck,BiRecycle,} from 'react-icons/bi'
import Overlay from '../Overlay/Overlay'
import DocsRow from './DocsRow'

const DocsBox = ({docsData, jobsData, baseUrl, refreshDocs, setOverlay}) => {
//
//HOOKS
//
  //docsDataBucket is what is rendered, and when filters are applied, this array state changes to match the filter conditions.
  //When this component renders, docsDataBucket takes the initial value of docsData prop.
  const [docsDataBucket, setDocsDataBucket] = useState(docsData)
  const [isUpdatingProcessed, setIsUpdatingProcessed] = useState(false)
  const [overlayState, setOverlayState] = useState("")

  //billSelectRef is the reference that is bound to the "doc type" select element.
  const billSelectRef = useRef()

  //jobsRef is the reference that is bound to the AIDSelect element.
  const jobsRef = useRef()

//
//EFFECTS
//


//
//FUNCS
//

 //filterFunc is where the filter magic happens (obviously).. I'll break down how the filtering function works.
 //Firstly docsData is deconstructed into docsDataCopy.
 //Secondly we map over that array, running if conditions to check the status of the select elements, captured by reference.
 //Third we check jobsRef.current.value, if == 'ALL', it won't change the filter. If it has a value, that is checked against the copy.
 //If the chosen AID matches the AID, ( doc[2] ), then that document is pushed to the matchingAIDArray.
 //Fourth we check billSelectRef.current.value, if it equals an empty string, it won't change the filter. If it has a value it is checked against the copy.
 //If the chosen docType matches the document type of the doc we're mapping over ( doc[0] ), then that document is pushed to matchingTypeArray.
 //Fifth, we establish an if condition with 3 outcomes; both filters active, jobs filter active, docType filter active.
 //In the first condition (both filters active), we map over the matchingTypeArray and check against the selected AID. If it matches, that document is pushed to combinedMatchesArray. Then docsDataBucket is assigned combinedMatchesArray.
 //In the second condition (docType aka billSelect is the only one active), we just assign docsDataBucket to matchingTypeArray
 //In the third condition (jobsRef is the only one active), we just assign docsDataBucket to matchingAIDArray
 const filterFunc = () => {
  console.log("filter running");

  const selectedAID = jobsRef.current.value;
  const selectedType = billSelectRef.current.value;

  const filtered = docsData.filter(doc => {
    const matchesAID = selectedAID === 'ALL' || doc[2] === selectedAID;
    const matchesType = selectedType === '' || doc[0] === selectedType;
    return matchesAID && matchesType;
  });

  console.log("Filtered Docs:", filtered);
  setDocsDataBucket(filtered);
};


 //resetFunc simply resets the filter. It sets docsDataBucket back to the entire list of documents (aka docsData prop)
 const resetFunc = () => {
  setDocsDataBucket(docsData);
  jobsRef.current.value = 'ALL';
  billSelectRef.current.value = '';
};




  const handleToggle = (doc) => {

    const updatedValue = doc[8] === 'TRUE' ? 'FALSE' : 'TRUE';
    updateDocument(docsData, null, null, updatedValue);
    const updatedDocs = docsDataBucket.map(d => {
      if (d[1] === doc[1]) {
        const updatedDoc = [...d];     // make a copy
        updatedDoc[8] = updatedValue;  // update In-Billing
        return updatedDoc;
      }
      return d;
    });
    setDocsDataBucket(updatedDocs);

  }

  const updateDocument = async(docData, newTotal, newNotes, newProcessed) => {
    let updatedTotal = docData[4]
    let updatedNotes = docData[7]
    if (newTotal !== null) {updatedTotal = newTotal}
    if (newNotes !== null) {updatedNotes = newNotes}
    const parcelData = {
      type: docData[0],
      DocID: docData[1],
      AID: docData[2],
      date: docData[3],
      total: updatedTotal,
      collected: 0,
      signature: docData[6],
      notes: updatedNotes,
      processed: newProcessed
    }
    try {
      setIsUpdatingProcessed(true)
      setOverlayState("waiting")
      const res = await fetch(baseUrl + 'updateDocument', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(parcelData),
      });
    
      const data = await res.json();
    
      if (!res.ok) {
        throw new Error(data?.error || 'Unknown error occurred');
      }
    } catch(err) {
      console.error(err)
      setOverlayState("failure")
      return;

    }

    setIsUpdatingProcessed(false)
  };

  
//
//RETURNS
//

//I won't go over the return too much, but do note how docsDataBucket is the state being mapped over.
//This component re-renders whenever the filters are updated or reset.

  return(
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div className='docsBox-header' style={{boxShadow: "0px 0px 20px black"}}>
      <div className='card-info-column filter'>
          <label htmlFor='typeField' className='medium-text'>Doc Type:</label>
          <select onChange={filterFunc} ref={billSelectRef} style={{width:"50%"}} className="input-field" name='typeField'>
            <option value=''>-</option>
            <option value='Change Order'>C.O.</option>
            <option value='Potential C.O.'>P.C.O</option>
            <option value='Estimate'>Estimate</option>
            <option value='Order'>Order</option>
            <option value='Work Order'>Work Order</option>
            <option value='Invoice'>Invoice</option>
          </select>
        </div>
        <div className='card-info-column filter'>
          <div className='medium-text'>AID</div>
          <AIDSelect onChangeMethod={filterFunc} chosenJobRef={jobsRef} jobsData={jobsData} defaultAID={'ALL'} />
        </div>

        <div className='card-info-column filter' style={{width: '20%'}}>
          <div className='medium-text'>Reset</div>
          <div className='input-field button orange' onClick={resetFunc} style={{fontSize: '1em', color:"#000"}}><BiRecycle /></div>
        </div>
      </div>
      <div className='submittals-wrapper'>
        <table className="styled-table">
          <tr>
            <td style={{width: "10%"}} >DOC ID</td>
            <td style={{width: "10%"}} >TYPE</td>
            <td style={{width: "5%"}} >AID</td>
            <td style={{width: "15%"}} >DATE</td>
            <td style={{width: "15%"}} >SIGNATURE</td>
            <td style={{width: "10%"}} >TOTAL $</td>

            {/* <td>Collected $</td> */}
            <td style={{width: "32%"}} >NOTES</td>
            <td style={{width: "10%"}} >PROCESSED</td>
          </tr>
          <tbody>
            {docsDataBucket.map((docData, index) => {
              if (docData[0] == "docuType 0") {return}
              return(
                // <DocsData refreshDocs={refreshDocs} jobsData={jobsData} baseUrl={baseUrl} docData={docData} setOverlay={setOverlay} key={Math.random()} />
                <DocsRow index={index} handleToggle={handleToggle} updateDocument={updateDocument} docData={docData} />
              )
            }
            )}
          </tbody>

        </table>
      </div>
      {isUpdatingProcessed && <Overlay setOverlay={setIsUpdatingProcessed} overlayState={overlayState} />}
    </div>
  )

}

export default DocsBox