import {useRef} from 'react'
const DocFooter = ({footerFormRef, submitForm}) => {

//
//HOOKS
//

//
//EFFECTS
//

//
//FUNCS
//

//
//RETURNS
//

//This incredibly simple return returns the signature select element and the submit button div, formatted inside a set of columns.

  const SignatureSelect = () => {
    return(
    <select style={{width:"50%"}} name="signatureField" className="input-field">
      <option value='Mike Belfonti'>Mike Belfonti</option>
      <option value='Sebastian Belfonti'>Sebastian Belfonti</option>
      <option value='Allison Belfonti'>Paul Flynn</option>
      <option value='Allison Belfonti'>Daniel Belfonti</option>
    </select>
    )
  }

  const NotesBox = () => {
    return(
      <div className='' style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
        <label htmlFor='totalField' className='medium-text'>Notes (optional):</label>
        <input style={{width:"50%"}} name="totalField" className='input-field' type="string"></input>
      </div>
    )
  }
  
  const SubmitButton = () => {
    return(
    <div name="buttonField" className="input-field button" style={{width:"100%"}} onClick={submitForm}>
      <div className="">SUBMIT</div>
    </div>
    )
  }

  return(
    <form ref={footerFormRef} >
      <div className='' style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
      <label className="medium-text" htmlFor='signatureField'>Signature:</label>
        <SignatureSelect />
      </div>
      <div className="field-column" style={{width:"100%"}}>
        <NotesBox />
      </div>
      <div className="field-column" style={{width:"100%"}}>
      <label className="medium-text" htmlFor='buttonField'>&nbsp;</label>
        <SubmitButton />
      </div>
    </form>
  )
}

export default DocFooter