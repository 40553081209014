import { useEffect, useState } from "react";
import '../App.css'

//No comment
const StagingPage = () => {
    return(
        <div className="staging-page"></div>
    )
    
}

export default StagingPage