import blueprint from './blueprint.jpg'

const TabServices = () => {
    return (
        <div className='tab-wrapper'>
            <div className='header-image-wrapper'>
                    <img className='header-image' src={blueprint}></img>
            </div>
            <div className='tab-body-wrapper'>
                <h1 className='tab-title'>Services</h1>
                <h3 className='tab-header'>Turn-Key</h3>
                <p className='tab-body'>What does this mean? Turn-Key means that when you hire us, you get the full package: supply of material, and the installation of said material.
                Being both the suppliers and installers, you can rest assured that the quality control of your project will be unmatched.
                On occasion we will take contract for either supply or installation alone, but that is rare. 
                </p>
                <h3 className='tab-header'>Finish Carpentry</h3>
                <ul className='tab-ul'>
                    <li className='tab-li'>Doors, both wooden interior doors and steel commercial doors.</li>
                    <li className='tab-li'>Moldings of all kinds. Baseboard, door & window casing, even detailed finishes commonly found in lobbies or high end homes.</li>
                    <li className='tab-li'>Door hardware. Ranging from simple leversets to complex commercial panic devices, we do it all.</li>
                    <li className='tab-li'>On select occasions we also design, supply, and install kitchen cabinetry.</li>
                </ul>
                
                <h3 className='tab-header'>Supply</h3>
                <p className='tab-body'>AMP Concepts prides itself on its ability to supply any architectural millwork product needed to complete the job. Couple that with 30 years of knowledge in this trade, and you are in for success.
                Before ordering, your job will be field verified. Meaning measuring the widths, heights, and swings of each door in the entire building.
                We have deep roots with long time manufacturers of moldings, doors, and hardware that ensures our customers are getting the best prices possible, without sacraficing quality or know-how in the process.
                Every order is meticulously reviewed against the Architectural plans before being processed.
                </p>
                <h3 className='tab-header'>Install</h3>
                <p className='tab-body'>Whats the purpose of having quality material if you don't have quality installers?
                At AMP Concepts we have joined forces with a couple of New Jersey's finest carpenters that have been in the business their entire lives.
                Not only are these men expedient enough to keep up to schedule with a large multifamily building, but also precise enough to install complex millwork pieces.
                </p>
                <h3 className='tab-header'>Behind The Scenes</h3>
                <p className='tab-body'>
                    Obviously all of our hard work does not take place in the field. Before we commit to any contracts, our team will do a thorough takeoff of the Architectural plans.
                    Doing this allows us to order exactly what material is needed, and be very knowledgable of the building before we step foot into it.
                    Before every job we provide an accurate and complete submittal package, so our customers know exactly what they will be receiving from us.
                    On rare occasion we may also design, supply, and install kitchen cabinetry! Our team has over 10 years experience in this field.
                    In summary, anything that deals with architectural millwork products (hence the name), we can likely do for you!
                </p>
            </div>
        </div>
    )
}
export default TabServices