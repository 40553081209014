import { useState, useEffect } from "react";

const Checks = ({jobsData,baseUrl,checksData,setOverlay}) => {
//
//HOOKS
//

//
//EFFECTS
//

//
//FUNCS
//

//
//RETURNS
//
return(<div>Checks</div>)
}
export default Checks