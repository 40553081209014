import {useState, useEffect, useRef} from 'react'
import {BiPencil, BiLockOpen, BiCheck, BiSave, BiX} from 'react-icons/bi'
import InvoicingCompany from '../MainComponents/InvoicingCompany'
import AIDSelect from '../MainComponents/AIDSelect'

const InoviceRow = ({baseUrl, invoiceData, vendorData, jobsData, setOverlay}) => {
//
//HOOKS
//

//localeStates (which I should have called dollarStates), holds the total & sales tax values.
//When coming in from the server, the total & sales tax come in as numbers.
//localeStates holds these true dollar sign values as strings, i.e. ( 10000 -> '$10,000' )
const [localeStates, setLocaleStates] = useState({
  total: 'Total',
  sales: 'Sales',
})

//editState toggles the edit state of the row.
const [editState, setEditState] = useState(false)

//changeInvoiceRef encapsulates the form that is used to edit the invoices.
const changeInvoiceRef = useRef()
//
//EFFECTS
//

//This useEffect it what converts the total & sales number types into true dollar currency strings.
useEffect(() => {
  if (isNaN(invoiceData[11])) {
  } else {
    let totalNum = +invoiceData[6]
    let totalSales = +invoiceData[7]
    let passThru = {
      total: totalNum.toLocaleString("en-US", {style:"currency", currency:"USD"}),
      sales: totalSales.toLocaleString("en-US", {style:"currency", currency:"USD"})
    }
    setLocaleStates(passThru)
  }
}, [])
//
//FUNCS
//

//invoiceChange is the function that runs when the user clicks save, after editing the invoice.
//First we check if there are any default or empty values, if there are, the user is alerted.
//Second (if nothing is default), we pack up the form into parcelFormData, and send it off to the server!
//If you want more detail about the appending of data to the form, read DocsBox.js

const invoiceChange =async()=> {
  console.log('test')
  if(changeInvoiceRef.current[0].value == 'ALL' || changeInvoiceRef.current[1].value == '' || changeInvoiceRef.current[2].value == '' || changeInvoiceRef.current[3].value == '' || changeInvoiceRef.current[4].value == '' || changeInvoiceRef.current[5].value == '' || changeInvoiceRef.current[6].value == '')
  {
    window.alert('YOU MUST FILL IN ALL FIELDS!')
  } else {
    setOverlay('waiting')
    const parcelFormData = new FormData()
    parcelFormData.append('docuType','invoice')
    parcelFormData.append('AID',changeInvoiceRef.current[0].value)
    parcelFormData.append('company',changeInvoiceRef.current[1].value)
    parcelFormData.append('type',changeInvoiceRef.current[2].value)
    parcelFormData.append('po',changeInvoiceRef.current[3].value)
    parcelFormData.append('sales',changeInvoiceRef.current[4].value)
    parcelFormData.append('total',changeInvoiceRef.current[5].value)
    parcelFormData.append('date',changeInvoiceRef.current[6].value)
    parcelFormData.append('description',changeInvoiceRef.current[7].value)
    parcelFormData.append('UIID', invoiceData[11])
    // console.log(changeInvoiceRef.current[0].value)
    // console.log(changeInvoiceRef.current[1].value)
    // console.log(changeInvoiceRef.current[2].value)
    // console.log(changeInvoiceRef.current[3].value)
    // console.log(changeInvoiceRef.current[4].value)
    // console.log(changeInvoiceRef.current[5].value)
    // console.log(changeInvoiceRef.current[6].value)

    const res = await fetch(baseUrl + 'editing/invoice', {
      method: 'POST',
      body: parcelFormData
    })
    .then((res) => {
      if (res.ok) {return res.json()} throw new Error('Bad Request')
    })
    .then(data => {
      console.log(data)
      setOverlay(data.response)
    })
    .catch((error) => [
      setOverlay('failure', error)
    ])

  }
}
//
//RETURNS
//

//InvoiceType is a simple selection element that deliniates between the chosen cost type.
const InvoiceType = () => {
  return(
    <select defaultValue={invoiceData[2]} className="input-field" name='typeField'>
      <option value=''>-</option>
      <option value='labor'>Labor</option>
      <option value='material'>Material</option>
      <option value='kitchens'>Kitchens</option>
      <option value='misc'>Misc.</option>
    </select>
  )
}

//The return statement here is very simple.
//After editState is checked to be true or false, we render either the default or edit state of the invoice.
if (editState == false){
  return(
    <div className="row-wrapper">
      <div className="cell-piece" style={{width: '5%'}}>{invoiceData[3]}</div>
      <div className="cell-piece" style={{width: '6%'}}>{invoiceData[10]}</div>
      <div className="cell-piece" style={{width: '15%'}}>{invoiceData[4]}</div>
      <div className="cell-piece" style={{width: '9%'}}>{invoiceData[2]}</div>
      <div className="cell-piece" style={{width: '10%'}}>{invoiceData[5]}</div>
      <div className="cell-piece" style={{width: '10%'}}>{localeStates.sales}</div>
      <div className="cell-piece" style={{width: '10%'}}>{localeStates.total}</div>
      <div className="cell-piece" style={{width: '15%'}}>{invoiceData[1]}</div>
      <div className="cell-piece desc" style={{width: '23%'}}>{invoiceData[8]}</div>
      <div className="cell-piece lock" style={{width: '4%'}} onClick={()=>setEditState(true)}><BiPencil /></div>
    </div>
  )
} else if (editState == true) {
  return(
    <form ref={changeInvoiceRef} className="row-wrapper">
      <div className="cell-piece save" style={{width: '5%'}} onClick={()=>{invoiceChange()}}><BiSave/></div>
      <div className="cell-piece" style={{width: '6%', overflow: 'hidden'}}>
        <AIDSelect jobsData={jobsData} defaultAID={invoiceData[3]}/>
      </div>
      <div className='cell-piece' style={{width: '15%', overflow: 'hidden'}}>
        <InvoicingCompany vendorData={vendorData} defaultVal={invoiceData[4]} />
      </div>
      <div className="cell-piece" style={{width: '9%', overflow: 'hidden'}}>
        <InvoiceType />
      </div>
      <input type="text" className="cell-piece input" style={{width: '10%'}} defaultValue={invoiceData[5]}></input>
      <input type="number" className="cell-piece input" style={{width: '10%'}} defaultValue={invoiceData[7]}></input>
      <input type="number" className="cell-piece input" style={{width: '10%'}} defaultValue={invoiceData[6]}></input>
      <input type="date" className="cell-piece input" style={{width: '15%'}} defaultValue={invoiceData[1]}></input>
      <textarea type="text" className="cell-piece input" style={{width: '23%'}} defaultValue={invoiceData[8]}></textarea>
      <div className="cell-piece lock cross" style={{width: '4%'}} onClick={()=>{setEditState(false)}}><BiX /></div>
    </form>
  )
} else {return(<div>One moment..</div>)}

  
}

export default InoviceRow