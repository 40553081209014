import DocHeader from "./DocHeader"
import DocFooter from "./DocFooter"
import {useState, useRef} from 'react';
import {Footer,BorderStyle, AlignmentType, TextRun, Packer, Table, TableRow, TableCell, Paragraph, ImageRun, Document, VerticalAlign, HeightRule, FooterWrapper} from "docx"
import { saveAs } from "file-saver";
import { Buffer } from "buffer";
import Overlay from '../Overlay/Overlay'

const DocGeneratorPage = ({jobsData,baseUrl,setOverlay,AMPInvoiceNum,updateAMPTag}) => {

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [overlayState, setOverlayState] = useState("")
    //footerFormRef references the footer form element that is pulled from DocFooter.js
    const footerFormRef = useRef()

    //headerFormRef references the header form element that is pulled from DocHeader.js.
    const headerFormRef = useRef()

    
//submitForm obviously.. submits the form, but I'll elaborate up here before the function.
//I believe this function totals close to 1,000 lines, so all comments will remain above, in an attempt not to lose comments in the mess that is this function.
//First: we run two if conditions to check & see if any of the input fields are empty or default, if they are empty strings, the user is alerted to fill in all fields.
//Second: we birth a new FormData object, and append all of the relevant key value pairs to the form.
//Third: we fetch the AMP logo. That fetched image is converted into a buffer, after applying the arrayBuffer() method to the image.
  //This is done to emulate fs.readFileSync, which DOCX (the package that returns a word doc) wants to receive.
//Fourth: on line 1071 a new Document object is established, and it pools together all of the individual doc objects I create, which I'll go over on the next few lines.
//Fifth: we build all of the different document components, which I'll list below.
  //logoTopper is a Paragraph object that contains the ImageRun (the logo), and goes at the top of the page.
  //headerTable contains header data like date, docType, and doc number. Do note the date is manipulated from its traditional YYYY-MM-DD to MM-DD-YYYY
  //up next is addressTable, which hosts the customer/vendor/contact information.
  //after that we have the "spacer table" which is where the user can add any details they'd like to about the particular document.
  //infoTable is the "header" of the table provided in each document. It contains relevant information like the signature, AID, and even a "terms and conditions" section.
  //the largest one is emptyTable, which is what provides the 8 empty rows for the user to fill in as they please. No information is needed for the empty table, it truly does come empty.
  //totalTable is the spaces for sales tax and total tax. Nothing fancy.
  //Lastly we get footerMessage!
//Sixth: we bundle the document into a format that the DOCX package requires, then it is automatically downloaded to the user's computer.
//Seventh: the parcelFormData is sent to the server. The fetch does have error handling.
//That's it! Simple when you look at it this way, huh?

const submitForm = async(e) => {
    e.preventDefault()
    setOverlayState("waiting")
    setIsSubmitting(true)
    if (headerFormRef.current[1].value == '' || headerFormRef.current[2].value == '' || headerFormRef.current[3].value == '') {return window.alert('PLEASE FILL IN ALL FIELDS')}
    // console.log(headerFormRef.current[0].value)
    // console.log(headerFormRef.current[1].value)
    // console.log(headerFormRef.current[2].value)
    // console.log(headerFormRef.current[3].value)

    // console.log(footerFormRef.current[0].value)
    const parcelFormData = {
        docuType: 'docBill',
        DocID: 'AMP-' + AMPInvoiceNum,
        AID: headerFormRef.current[0].value,
        date: headerFormRef.current[1].value,
        total: headerFormRef.current[2].value,
        type: headerFormRef.current[3].value,
        signature: footerFormRef.current[0].value,
        notes: footerFormRef.current[1].value
      };
    //
    //HERE IS WHERE I SEND parcelFormData to backend.
    
    
    
    //
    //DOCX
    //
    let imgurLogo = await fetch ('https://i.imgur.com/tSIfb53.png')
    let imgurBuffer = Buffer.from(await imgurLogo.arrayBuffer())
    
    const logoTopper = new Paragraph({children: [
      new ImageRun({
        data: imgurBuffer,
        transformation: {
          width: 120,
          height: 110,
        }
      })
    ],alignment: AlignmentType.CENTER })
    
    const dateData = new Date();
    let dateFormatted = dateData.getMonth() + 1 + '/' + dateData.getDate() + '/' + dateData.getFullYear();
    
    const headerTable = new Table({
      rows: [
        new TableRow({children:[
          new TableCell({
            borders: {
              top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
              bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
              left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            },children:[
              new Paragraph({children:[
                new TextRun({
                  text: 'PO #:  ',
                  font: "Arial",
                  size: 22,
                }),
                new TextRun({
                  text: 'AMP-' + AMPInvoiceNum,
                  bold: true,
                  font: "Arial",
                  size: 24,
    
                })
              ],
              alignment: AlignmentType.CENTER,
              }),
          ],verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          borders: {
            top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          },children:[
            new Paragraph({children:[
              new TextRun({
                text: headerFormRef.current[3].value,
                bold: true,
                font: "Arial",
                size: 24,
    
              })
            ],
            alignment: AlignmentType.CENTER,
            }),
        ],verticalAlign: VerticalAlign.CENTER,
      }),
          new TableCell({borders: {
            top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            right: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          },children:[
              new Paragraph({children:[
                new TextRun({
                  text: 'Date:  ',
                  font: "Arial",
                  size: 22,
    
                }),
                new TextRun({
                  text: dateFormatted,
                  bold: true,
                  font: "Arial",
                  size: 24,
    
                })
              ],
              alignment: AlignmentType.CENTER,
              })
          ],
          verticalAlign: VerticalAlign.CENTER,
        })
        ]})
      ],
      width: 0, // AUTO
      columnWidths: [3000, 3100, 3000], // total page width is 9638 DXA for A4 portrait
    
    // indent: {
    //     size: 600,
    //     type: WidthType.DXA,
    // }
    })
    
    const smallSpacer = new Paragraph({children:[
      new TextRun({
        text: '',
        size: 20,
        color: 'FFFFFF',
      })
    ]
    })
    
    const spacerTable = new Table({rows:[
      new TableRow({children:[
        new TableCell({borders: {
          top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          right: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        },children:[
          new Paragraph({children: [
            new TextRun({
              text: 'Add any details here..'
            })
          ]})
        ]})
      ], height: {value: 1500, rule: HeightRule.EXACT}})
    ], width:0, columnWidths:[9100,0] })
    
    const infoTable = new Table({rows: [
      new TableRow({children:[
        new TableCell({children:[
          new Paragraph({children:[
            new TextRun({
              text: 'CREATED BY'
            })
          ],alignment: AlignmentType.CENTER}),
          new Paragraph({children:[
            new TextRun({
              text: '',
              size: 8,
              color: 'FFFFFF',
            })
          ]
          }),
          new Paragraph({children:[
            new TextRun({
              text: footerFormRef.current[0].value,
              bold: true,
              size: 24,
            })
          ],alignment: AlignmentType.CENTER})
        ]}),
        new TableCell({children:[
          new Paragraph({children:[
            new TextRun({
              text: 'JOB #'
            })
          ],alignment: AlignmentType.CENTER}),
          new Paragraph({children:[
            new TextRun({
              text: '',
              size: 8,
              color: 'FFFFFF',
            })
          ]
          }),
          new Paragraph({children:[
            new TextRun({
              text: headerFormRef.current[0].value,
              bold: true,
              size: 24,
            })
          ],alignment: AlignmentType.CENTER})
        ]}),
        new TableCell({children:[
          new Paragraph({children:[
            new TextRun({
              text: 'TERMS'
            })
          ],alignment: AlignmentType.CENTER}),
          new Paragraph({children:[
            new TextRun({
              text: '',
              size: 8,
              color: 'FFFFFF',
            })
          ]
          }),
          new Paragraph({children:[
            new TextRun({
              text: "Due in 30 days",
              bold: true,
              size: 24,
            })
          ],alignment: AlignmentType.CENTER})
        ]}),
        
      ], height: {value: 800, rule: HeightRule.EXACT}})
    ], width: 0, columnWidths: [3100, 3100, 2900]})
    
    
    const emptyTable = new Table({rows:[
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '#',
            font: 'Arial',
            bold: true,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ],verticalAlign: VerticalAlign.CENTER,}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: 'DESCRIPTION',
            font: 'Arial',
            bold: true,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ],verticalAlign: VerticalAlign.CENTER,}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: 'QTY',
            font: 'Arial',
            bold: true,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ],verticalAlign: VerticalAlign.CENTER,}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: 'UNIT PRICE',
            font: 'Arial',
            bold: true,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ],verticalAlign: VerticalAlign.CENTER,}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: 'SUM PRICE',
            font: 'Arial',
            bold: true,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ],verticalAlign: VerticalAlign.CENTER,}), 
    ], height: {value: 400, rule: HeightRule.EXACT}}),
    
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}),
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
    ]}),
    
    ]}),
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
    ]}),
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
    ]}),
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.CENTER}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
    ]}),
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
    ]}),
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
    ]}),
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
    ]}),
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
    ]}),
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
    ]}),
    new TableRow({children:[
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ],alignment: AlignmentType.CENTER})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.LEFT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text: '',
            font: 'Arial',
            bold: false,
            size: 20,
          })
        ]})
      ],alignment: AlignmentType.RIGHT}), 
    ]}),
    ],width: 0, columnWidths: [500, 5000, 700, 1400, 1500]})
    
    const totalClone = +headerFormRef.current[2].value
    
    
    const totalTable = new Table({rows: [

      new TableRow({children:[
        new TableCell({borders: {
          top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        },children:[
          new Paragraph({children:[
            new TextRun({
              text: 'TOTAL DUE: ',
              bold: true,
              size: 24,
            })
          ],alignment: AlignmentType.RIGHT})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text:totalClone.toLocaleString("en-US", {style:"currency", currency:"USD"}),
            bold: true,
            size: 24,
            })
          ],alignment: AlignmentType.RIGHT})
        ]})
      ]})
    ],width: 0, columnWidths: [7600, 1500]})
    
    const footerMessage = new Footer({children:[

      new Paragraph({children:[
        new TextRun({
          text: 'THANK YOU FOR YOUR BUSINESS!',
          italics: true,
          font: 'EB Garamond',
          size: 24,
        })
      ],alignment: AlignmentType.CENTER}),
    ]})
    
    const billDoc = new Document({
      sections: [
          {
              properties: {
                page: {
                  margin: {
                    top: 400
                  }
                }
              },
              footers:{
                default: footerMessage
              },
              children: [
                logoTopper,
                smallSpacer,
                headerTable,
                smallSpacer,
                infoTable,
                smallSpacer,
                emptyTable,
                smallSpacer,
                totalTable,
                smallSpacer
              ],
          },
      ],
    });
    
    Packer.toBlob(billDoc).then((blob) => {
      console.log(blob);
      saveAs(blob, 'AMP-' + AMPInvoiceNum + ' Document');
      console.log("Document created successfully");
    });
    try {
        const res = await fetch(baseUrl + 'createDocument', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(parcelFormData),
        });
      
        const data = await res.json();
      
        if (!res.ok) {
          throw new Error(data?.error || 'Unknown error occurred');
        }
      
        console.log(data);
        // setOverlayState("complete")
        setIsSubmitting(false)
        updateAMPTag();
      
      } catch (error) {
        console.error('Request failed:', error);
        setOverlayState('failure', error.message || "request failed")
        // setOverlay('failure', error.message || 'Request failed');
      }
      
    
    //END OF SUBMIT FORM
    }

    return(
        <>
            <DocHeader headerFormRef={headerFormRef} jobsData={jobsData} />
            <DocFooter submitForm={submitForm} footerFormRef={footerFormRef} />
            {isSubmitting && <Overlay setOverlay={setIsSubmitting} overlayState={overlayState} />}
        </>
    )

}

export default DocGeneratorPage