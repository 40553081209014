import {useState, useRef, useEffect} from 'react'
import AIDSelect from '../MainComponents/AIDSelect'

const DocHeader = ({jobsData, headerFormRef}) => {
//
//HOOKS
//

//
//EFFECTS
//

//
//FUNCS
//

//
//RETURNS
//

//This incredibly simple return returns AIDSelect (job select), as well as a date input field, price input field, and cost type input field.

return(
  <form ref={headerFormRef} className='doc-header'>
    <div style={{display: "flex",flexDirection:"row", justifyContent:"space-between"}}>
      <label htmlFor='aidSelect' className='medium-text'>AMP Id:</label>
      <AIDSelect defaultAID={'A00'} jobsData={jobsData}/>
    </div>

    <div className='' style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
      <label htmlFor='dateField' className='medium-text'>Date:</label>
      <input
        style={{width:"50%"}}
        name="dateField"
        className='input-field'
        type="date"
        defaultValue={new Date().toISOString().split('T')[0]}
      />
    </div>
      <div className='' style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
        <label htmlFor='totalField' className='medium-text'>Total Price $:</label>
        <input defaultValue={0} style={{width:"50%"}} name="totalField" className='input-field' type="number"></input>
      </div>
      <div className='' style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
        <label htmlFor='typeField' className='medium-text'>Doc Type:</label>
        <select style={{width:"50%"}} className="input-field" name='typeField'>
          <option value=''>-</option>
          <option value='Change Order'>C.O.</option>
          <option value='Potential C.O.'>P.C.O</option>
          <option value='Estimate'>Estimate</option>
          <option value='Order'>Order</option>
          <option value='Work Order'>Work Order</option>
          <option value='Invoice'>Invoice</option>
        </select>
      </div>
      
  </form>
  
)}
export default DocHeader