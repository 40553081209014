import { memo, useState, useEffect, useRef } from "react";
import InvoiceRow from './InvoiceRow'
import AIDSelect from "../MainComponents/AIDSelect";
import InvoicingCompany from "../MainComponents/InvoicingCompany";
import {BiCheck,BiRecycle,} from 'react-icons/bi'

const InvoiceData = ({jobsData,baseUrl,invoicesData,setOverlay,vendorData}) => {
//
//HOOKS
//

//ATTENTION: This page will not have a lot of comments because most of it is filtering logic.
//If you want to get all the juicy details on how the filter logic works, please read DocsBox.js

const [dataContainer, setDataContainer] = useState()
const chosenJobRef = useRef('')
const filterFormRef = useRef()
const [summedFilteredInvoices,setSummedFilteredInvoices] = useState("")
//
//EFFECTS
//
useEffect(()=> {
  setDataContainer(invoicesData)
}, [])
//
//FUNCS
//
const filterFunc = () => {
  console.log(invoicesData)
  console.log(filterFormRef.current[0].value)
  console.log(filterFormRef.current[1].value)
  let invoicesDataCopy = [...invoicesData]
  let matchingAIDArray = []
  let matchingTypeArray = []
  let combinedMatchesArray = []
  invoicesDataCopy.map(invoice => {
    if (filterFormRef.current[0].value == 'ALL') {

    } else if (invoice[3] == filterFormRef.current[0].value) {
      // console.log('job match!')
      matchingAIDArray.push(invoice)
    }
    if (filterFormRef.current[1].value == '') {

    } else if (invoice[4] == filterFormRef.current[1].value) {
      // console.log('type match!')
      matchingTypeArray.push(invoice)
    }
    
  })
  if (filterFormRef.current[1].value != '' && filterFormRef.current[0].value != 'ALL') {
        matchingTypeArray.map((typeMatch) => {
          if (filterFormRef.current[0].value == typeMatch[3]) {
            combinedMatchesArray.push(typeMatch)
          }
        })
        console.log('combined', combinedMatchesArray)
        setDataContainer(combinedMatchesArray)
  } else if (filterFormRef.current[1].value != '' && filterFormRef.current[0].value == 'ALL') {
    setDataContainer(matchingTypeArray)
    let passThruNumber = 0
    matchingTypeArray.map(invoiceData => {
      let totalNum = +invoiceData[6]
      let totalSales = +invoiceData[7]
      passThruNumber += (totalNum + totalSales)
      console.log(passThruNumber)
    });
    setSummedFilteredInvoices(passThruNumber.toLocaleString())
  } else if (filterFormRef.current[1].value == '' && filterFormRef.current[0].value != 'ALL') {
    setDataContainer(matchingAIDArray)
    let passThruNumber = 0
    matchingAIDArray.map(invoiceData => {
      let totalNum = +invoiceData[6]
      let totalSales = +invoiceData[7]
      passThruNumber += (totalNum + totalSales)
      console.log(passThruNumber)
    });
    setSummedFilteredInvoices(passThruNumber.toLocaleString())
  }


  
}
const resetFunc = () => {
  setDataContainer(invoicesData)
}


//
//RETURNS
//
// const InvoiceType = () => {
//   return(
//     <select className="input-field" name='typeField'>
//       <option value=''>-</option>
//       <option value='labor'>Labor</option>
//       <option value='material'>Material</option>
//       <option value='kitchens'>Kitchens</option>
//       <option value='misc'>Misc.</option>
//     </select>
//   )
// }


//DataContainerTable is the return that actually maps over each invoice, and prints the InvoiceRow functional component.
const DataContainerTable = () => {
  if (dataContainer) {
    return(
      <div className="table-wrapper smallish-text">
        {dataContainer && dataContainer.map((invoice, index) => <InvoiceRow vendorData={vendorData} setOverlay={setOverlay} baseUrl={baseUrl} invoiceData={invoice} jobsData={jobsData} index={index} key={Math.random()} />)}
      </div>
    )
  }
}

  //At the top of this return we have the filter.. refer to DocsBox.js for more..
  //Below that we have the "header" row that simply gives title to each row of the invoice table.
  //And at the very bottom you'll see DataContainerTable, which encapsulates all rows (except header obviously)..
  return(
    <div className="page-stage largest" style={{}}>
      <form ref={filterFormRef} className="field-row">
        <div className="field-column small">
          <label className="medium-text">AID Filter:</label>
          <AIDSelect defaultAID={'ALL'} jobsData={jobsData} chosenJobRef={chosenJobRef}/>
        </div>
        <div className="field-column small">
          <label className="medium-text">Company Filter:</label>
          <InvoicingCompany vendorData={vendorData} defaultVal={''} />
        </div>
        <div className='card-info-column filter' style={{width: '20%'}}>
          <div className='medium-text'>FILTER</div>
          <div className='input-field button' onClick={filterFunc} style={{fontSize: '2em'}}><BiCheck /></div>
        </div>
        <div className='card-info-column filter' style={{width: '20%'}}>
          <div className='medium-text'>RESET</div>
          <div className='input-field button orange' onClick={resetFunc} style={{fontSize: '2em'}}><BiRecycle /></div>
        </div>
      </form>
      <hr></hr>
      <div className="row-wrapper top">
        <div className="cell-piece" style={{width: '5%'}}>AID</div>
        <div className="cell-piece" style={{width: '6%'}}>% Paid</div>
        <div className="cell-piece" style={{width: '15%'}}>Company</div>
        <div className="cell-piece" style={{width: '9%'}}>Type</div>
        <div className="cell-piece" style={{width: '10%'}}>PO #</div>
        <div className="cell-piece" style={{width: '10%'}}>Tax</div>
        <div className="cell-piece" style={{width: '10%'}}>Subtotal</div>
        <div className="cell-piece" style={{width: '15%'}}>Date</div>
        <div className="cell-piece" style={{width: '23%'}}>Description</div>
        <div className="cell-piece lock" style={{width: '4%'}}></div>
      </div>
      <DataContainerTable />
      {summedFilteredInvoices != "" && (
        <div className="row-wrapper top">
        <div className="cell-piece" style={{width: '5%'}}>-</div>
        <div className="cell-piece" style={{width: '6%'}}>-</div>
        <div className="cell-piece" style={{width: '15%'}}>-</div>
        <div className="cell-piece" style={{width: '9%'}}>-</div>
        <div className="cell-piece" style={{width: '10%'}}>-</div>
        <div className="cell-piece" style={{width: '10%'}}>-</div>
        <div className="cell-piece" style={{width: '10%'}}>-</div>
        <div className="cell-piece" style={{width: '15%', fontWeight:"bold"}}>Invoices Total:</div>
        <div className="cell-piece" style={{width: '23%',}}>${summedFilteredInvoices}</div>
        <div className="cell-piece lock" style={{width: '4%'}}></div>
      </div>
      )}
      
    </div>
    )

}
export default InvoiceData