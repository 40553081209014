import { useState, useEffect, useRef } from "react";
import AIDSelect from './AIDSelect'
import FileDragDrop from './FileDragDrop'
import InvoicingCompany from './InvoicingCompany'

const Invoicing = ({jobsData,baseUrl,setOverlay,vendorData}) => {
//
//HOOKS
//
const invoiceFormRef = useRef()
const uploadedFileContainerRef = useRef(null)
const chosenJobRef = useRef('')
//
//EFFECTS
//

//
//FUNCS
//
const setFile = (file) => {
  uploadedFileContainerRef.current = file
  
}

//submitForm has a few layers, which I'll go over in detail.
//First it checks to see if any of the input fields are empty. If they are, the function returns a window.alert('GET YOUR SHIT TOGETHER MORON')
//Second, if all input fields are filled, a new FormData() is created, and all input field values are appended to it. This also includes the files, which are appended with a for loop just in case there are multiple.
//The entire reason I use FormData() objects to append all of my formData to is so I can easily move files. I understand this unncessary if I'm not appending files, but I like it as a universal practice for sending things to the backend.
//Lastly, after all appending is done, we send parcelFormData off to the castle in the cloud(s).
async function submitForm() {
  if(invoiceFormRef.current[2].value == '' || invoiceFormRef.current[3].value == '' || invoiceFormRef.current[4].value == '' || invoiceFormRef.current[5].value == '' || invoiceFormRef.current[6].value == '' || invoiceFormRef.current[7].value == '')
  {
    window.alert('YOU MUST FILL IN ALL FIELDS!')
  } else {
    if(uploadedFileContainerRef.current != null) {
      setOverlay('waiting')
      let parcelFormData = new FormData()
      for (let i = 0; i < uploadedFileContainerRef.current.length; i++) {
        parcelFormData.append('file', uploadedFileContainerRef.current[i])
      }
      parcelFormData.append('AID',invoiceFormRef.current[1].value)
      parcelFormData.append('company',invoiceFormRef.current[2].value)
      parcelFormData.append('po',invoiceFormRef.current[3].value)
      parcelFormData.append('date',invoiceFormRef.current[4].value)
      parcelFormData.append('type',invoiceFormRef.current[5].value)
      parcelFormData.append('sales',invoiceFormRef.current[6].value)
      parcelFormData.append('total',invoiceFormRef.current[7].value)
      parcelFormData.append('description',invoiceFormRef.current[8].value)
      parcelFormData.append('signature',invoiceFormRef.current[9].value)
      const res = await fetch(baseUrl + 'invoicing/uploadInvoice', {
        method: 'POST',
        body: parcelFormData
      })
      .then((res) => {
        if (res.ok) {return res.json()} throw new Error('Bad Request')
      })
      .then(data => {
        console.log(data)
        setOverlay(data.response)
      })
      .catch((error) => [
        setOverlay('failure', error)
      ])
    } else {
      window.alert('YOU MUST ATTACH A FILE OR PICTURE!')
    }
  // }

  }
}

//
//RETURNS
//

//Too lazy to go over the returns.
//It's pretty easy, just a form data with lots of selects and input elements.

const InvoiceType = () => {
  return(
    <select className="input-field" name='typeField'>
      <option value=''>-</option>
      <option value='labor'>Labor</option>
      <option value='material'>Material</option>
      <option value='kitchens'>Kitchens</option>
      <option value='misc'>Misc.</option>
    </select>
  )
}

const SignatureSelect = () => {
  return(
  <select name="signatureField" className="input-field">
    <option value='seb'>Seb Belfonti</option>
    <option value='allison'>Allison Belfonti</option>
    <option value='mike'>Mike Belfonti</option>
  </select>
  )
}

const SubmitButton = () => {
  return(
  <div name="buttonField" className="input-field button" onClick={submitForm}>
    <div className="">SUBMIT</div>
  </div>
  )
}

return(
<form ref={invoiceFormRef} className="page-stage medium">
  
    <label className="medium-text" htmlFor=''>Drag & Drop or Upload Invoice:</label>
    <FileDragDrop setFile={setFile}/>
    <hr></hr>
    <label className="medium-text" htmlFor='aidSelect'>AMP ID:</label>
    <AIDSelect defaultAID={'A00'} jobsData={jobsData} chosenJobRef={chosenJobRef}/>
    <div className="field-row">
      <div className="field-column">
      <label className="medium-text" htmlFor='companyField'>Invoicing Company:</label>
        <InvoicingCompany vendorData={vendorData} />
        <label className="medium-text" htmlFor='poField'>Invoice PO#:</label>
        <input name="poField" className="input-field" type="text"></input>
        <label className="medium-text" htmlFor='dateField'>Date on Invoice:</label>
        <input name="dateField" className="input-field" type="date"></input>
      </div>
      <div className="field-column">
      <label className="medium-text" htmlFor='typeField'>Type of Invoice:</label>
        <InvoiceType />
        <label className="medium-text" htmlFor='taxField'>Sales Tax:</label>
        <input name="taxField" className="input-field" type='number'></input>
        <label className="medium-text" htmlFor='totalField'>Total (Without Tax):</label>
        <input name="totalField" className="input-field" type="number"></input>
      </div>
    </div>
    <hr></hr>
    <label className="medium-text" htmlFor='descriptionField'>Brief & Detailed Description:</label>
    <textarea name="descriptionField" className="description-field"></textarea>
    
    <div className="field-row">
      <div className="field-column">
      <label className="medium-text" htmlFor='signatureField'>Signature:</label>
        <SignatureSelect />
      </div>
      <div className="field-column">
      <label className="medium-text" htmlFor='buttonField'>&nbsp;</label>
        <SubmitButton />
      </div>
    </div>
</form>
)
}
export default Invoicing