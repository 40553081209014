import './App.css'
import {useState, useEffect, useRef} from 'react'

const HardwareLineComponent = ({itemData}) => {
    const [hardwareData, setHardwareData] = useState([0,0,0,0,0,0])

    useEffect(() => {
        try {
            setHardwareData(itemData)
        } catch(err) {
            console.error(err)
        }
        
    }, [])


    return (
        <div className="hardware-line-wrapper">
                <div className="info-cell hardware header">{hardwareData[1]}</div>
                <div className="info-cell hardware header">{hardwareData[2]}</div>
                <div className="info-cell hardware header">{hardwareData[3]} </div>
                <div className="info-cell hardware header">{hardwareData[4]} </div>
                <div className="info-cell hardware header">{hardwareData[5]} </div>
        </div>
    )
}

export default HardwareLineComponent