import { useState, useEffect, useRef } from "react"
import logo from './watermark.png'
import logoTitle from './watermark-title.png'
import './App.css'
import HardwareBlockComponent from "./HardwareBlockComponent"
import HardwareLineComponent from "./HardwareLineComponent"
import HardwareSmallBlockComponent from "./HardwareSmallBlockComponent"
import HardwareTinyBlockComponent from "./HardwareTinyBlockComponent"

const PrintPage = ({doorCode, hardwareCodes, hardwareRepo, doorImage, hardwareToggle, index}) => {
    // console.log(doorImage[0])
    // console.log(hardwareCodes)
    // console.log(hardwareRepo)


    const [matchingHardwareItems, setMatchingHardwareItems] = useState([0,0])
    const [matchingDoorImage, setMatchingDoorImage] = useState(doorImage[0])
    
    const imgURLRef = useRef()

    useEffect(() => {
        let hardwareCodesCopy = [...hardwareCodes]
        let hardwareRepoCopy = [...hardwareRepo]
        let hardwareCodesArray = hardwareCodesCopy[0]
        try {
            // console.log('Match!')
            // console.log(hardwareCodesArray)
            let arrayOfHardwares = []
            for (let i=0;i<hardwareCodesArray.length;i++) {
                const matchedItemCode = hardwareRepoCopy.filter(piece => hardwareCodesArray[i] == piece[0])
                arrayOfHardwares.push(matchedItemCode)
            }
            arrayOfHardwares.shift()
            // console.log(doorCode[0], doorCode[10], arrayOfHardwares)
            setMatchingHardwareItems(arrayOfHardwares)
            setMatchingDoorImage(doorImage[0][1])
        } catch(err) {
            console.error('This door ' + doorCode[0] + ' does not have any matching hardware codes! Or does not have an image match!')
            // console.error(err)
        }
    }, [])


const LineOrBlockSplitter =() => {
    if (hardwareToggle === true) {
        if (matchingHardwareItems.length > 12) {
            return(
            <div className="page-row-wrapper hardware">
                <div className="hardware-line-wrapper">
                    <div className="info-cell hardware header">MNFR</div>
                    <div className="info-cell hardware header">ITEM DESC</div>
                    <div className="info-cell hardware header">MODEL#</div>
                    <div className="info-cell hardware header">FINISH</div>
                    <div className="info-cell hardware header">QTY</div>
                </div>
                {matchingHardwareItems && matchingHardwareItems.map(itemData => <HardwareLineComponent itemData={itemData[0]} key={Math.random()}/>)}
            </div>
            )
        } else if (matchingHardwareItems.length > 10) {
            return (
                <div className="page-row-wrapper hardware">
                    {matchingHardwareItems && matchingHardwareItems.map(itemData => <HardwareTinyBlockComponent itemData={itemData[0]} key={Math.random()}/>)}
                </div>
            )
        } else if (matchingHardwareItems.length > 8) {
            return (
                <div className="page-row-wrapper hardware">
                    {matchingHardwareItems && matchingHardwareItems.map(itemData => <HardwareSmallBlockComponent itemData={itemData[0]} key={Math.random()}/>)}
                </div>
            )
        } else {
            return (
                <div className="page-row-wrapper hardware">
                    {matchingHardwareItems && matchingHardwareItems.map(itemData => <HardwareBlockComponent itemData={itemData[0]} key={Math.random()}/>)}
                </div>
            )
        }
    } else {return}
}


//<div style={{width: '0%'}} className="info-cell"></div>
    return (
        <div className="page-wrapper">
            <div className="body-wrapper">
                {/* <div className="watermark-wrapper">
                <img className="logo-img" style={{width: "40%"}} src={logo}></img>
                </div> */}
                <div className="page-row-wrapper">
                    <div className="img-frame">
                        <img className="item-img" src={matchingDoorImage}></img>
                    </div>
                    <div className="door-info-wrapper">
                        <div className="info-cell-stack">
                            <div className="info-cell stacked" style={{fontWeight: 'bold'}}>•{doorCode[0]}</div>
                            <div className="info-cell stacked">•HW Set: {doorCode[13]}</div>
                            <div className="info-cell stacked">•Rough Opening: {doorCode[19]}</div>
                        </div>
                        <div className="info-cell-stack">
                            <div className="info-cell stacked">•Total: {doorCode[3]}</div>
                            <div className="info-cell stacked">•Twin?: {doorCode[4]}</div>
                            <div className="info-cell stacked">•Hand: {doorCode[5]}</div>
                        </div> 
                        <div className="info-cell-stack">
                            <div className="info-cell stacked">•Width: {doorCode[6]}</div>
                            <div className="info-cell stacked">•Height: {doorCode[7]}</div>
                            <div className="info-cell stacked">•Thickness: {doorCode[8]}</div>
                        </div>
                        <div className="info-cell-stack">
                            <div className="info-cell stacked">•Material: {doorCode[9]}</div>
                            <div className="info-cell stacked">•Profile: {doorCode[11]}</div>
                            <div className="info-cell stacked">•Bore: {doorCode[12]}</div>
                        </div>
                        <div className="info-cell-stack">
                            <div className="info-cell stacked">•Frame Type: {doorCode[18]}</div>
                            <div className="info-cell stacked">•Throat: {doorCode[15]}</div>
                            <div className="info-cell stacked">•Fire Label Mins: {doorCode[10]}</div>
                        </div>
                        <div className="info-cell">•Notes: {doorCode[17]}</div>
                    </div>
                    
                </div>
                <LineOrBlockSplitter />
                
            </div>
        </div>
    )
}
export default PrintPage