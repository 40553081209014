import DeugenVibe from './deugen-vibe.png'
import meridiaTransit from './meridiaTransit.jpeg'
import silverLake from './silverLake.jpeg'
import plainfield from './plainfield.jpeg'
import theVibe from './theVibe.jpeg'
import brownstones from './brownstones.png'
import cobaltLofts from './cobalt-lofts.jpg'
import lifeStyles from './lifestyles.jpeg'
import bridgeWater from './sunrise-bridgewater.jpg'
import franklinLakes from './sunrise-franklinLakes.jpg'
import mountainLakes from './sunrise-mountainLakes.jpg'
import shrewsbury from './sunrise-shrewsbury.jpg'
import weehawken from './weehawken.png'


const TabPortfolio = () => {

    return (
        <div className='tab-wrapper'>
            <div className='header-image-wrapper'>
                    <img className='header-image' src={meridiaTransit}></img>
            </div>
            <div className='portfolio-wrapper'>


                <div className='display-wrapper'>
                    <img className='image-display' src={brownstones}></img>
                    <div className='image-display-description'>Rahway Brownstones</div>
                </div>
                <div className='display-wrapper'>
                    <img className='image-display' src={franklinLakes}></img>
                    <div className='image-display-description'>Franklin Lakes Senior Living</div>
                </div>
                <div className='display-wrapper'>
                    <img className='image-display' src={lifeStyles}></img>
                    <div className='image-display-description'>Linden Lifestyles</div>
                </div>
                <div className='display-wrapper'>
                    <img className='image-display' src={weehawken}></img>
                    <div className='image-display-description'>Park Ave Weehawken</div>
                </div>


                <div className='display-wrapper'>
                    <img className='image-display' src={silverLake}></img>
                    <div className='image-display-description'>Silver Lake</div>
                </div>

                <div className='display-wrapper'>
                    <img className='image-display' src={meridiaTransit}></img>
                    <div className='image-display-description'>Transit @ Dover</div>
                </div>

                <div className='display-wrapper'>
                    <img className='image-display' src={theVibe}></img>
                    <div className='image-display-description'>The Vibe</div>
                </div>

                <div className='display-wrapper'>
                    <img className='image-display' src={plainfield}></img>
                    <div className='image-display-description'>1000 Plainfield</div>
                </div>

                

                <div className='display-wrapper'>
                    <img className='image-display' src={cobaltLofts}></img>
                    <div className='image-display-description'>Harrison Cobalt Lofts</div>
                </div>
                
                <div className='display-wrapper'>
                    <img className='image-display' src={bridgeWater}></img>
                    <div className='image-display-description'>Bridgewater Senior Living</div>
                </div>
                
                <div className='display-wrapper'>
                    <img className='image-display' src={mountainLakes}></img>
                    <div className='image-display-description'>Mountain Lakes Senior Living</div>
                </div>
                <div className='display-wrapper'>
                    <img className='image-display' src={shrewsbury}></img>
                    <div className='image-display-description'>Shrewsbury Senior Living</div>
                </div>
                
            
            </div>   
        </div>
    )
}
export default TabPortfolio