import {useRef, useState, useEffect} from 'react'

const DocsRow = ({index, handleToggle, updateDocument, docData}) => {

  const totalRef = useRef()
  const notesRef = useRef()

  const [isTotalDoubleClicked, setIsTotalDoubleClicked] = useState(false)
  const [isNotesDoubleClicked, setIsNotesDoubleClicked] = useState(false)
  const [isProcessed, setIsProcessed] = useState()

  useEffect(()=>{
    setIsProcessed(docData[8]==="TRUE"?true:false)
  },[docData])

  const totalDoubleClick = () => {

    if (isTotalDoubleClicked) {
      setIsTotalDoubleClicked(false)
      updateDocument(docData, totalRef.current.value, notesRef.current.value, isProcessed)
    } else {
      setIsTotalDoubleClicked(true)
    }
  }

  const notesDoubleClick = () => {
    if (isNotesDoubleClicked) {
      setIsNotesDoubleClicked(false)
      updateDocument(docData, totalRef.current.value, notesRef.current.value, isProcessed)
    } else {
      setIsNotesDoubleClicked(true)
    }
  }

  const makeNewStringDate = (date) => {
    if (!date) {return "N/A"}
    let dateSplit = date.split('-')
    return dateSplit[1] + '/' + dateSplit[2] + '/' + dateSplit[0]
  }

  return(
    <tr key={index}>
      <td>{docData[1] || "N/A"}</td>
      <td>{docData[0] || "N/A"}</td>
      <td>{docData[2] || "N/A"}</td>
      <td>{docData[6] || "N/A"}</td>
      <td>{makeNewStringDate(docData[3])}</td>
      {isTotalDoubleClicked
        ?<td className="hover-cell" onDoubleClick={totalDoubleClick} style={{textAlign:"right"}}>
          <input ref={totalRef} type="number" defaultValue={Number(docData[4]) || 0} />
        </td>
        :<td className="hover-cell" onDoubleClick={totalDoubleClick} style={{textAlign:"right"}}>${Number(docData[4]).toLocaleString("en-US") || "N/A"}</td>
      }
      {/* <td>{docData[5]}</td> */}
      {/* <td>{docData[7] || "--"}</td> */}
      {isNotesDoubleClicked
        ?<td className="hover-cell" onDoubleClick={notesDoubleClick}>
          <input type="text" defaultValue={docData[7]}></input>
        </td>
        :<td className="hover-cell" onDoubleClick={notesDoubleClick}>{docData[7] || "--"}</td>
      }
        <td >
          <button
            onClick={() => handleToggle(docData)}
            style={{
              backgroundColor: docData[8] === "TRUE" ? '#4CAF50' : '#ccc',
              border: '1px solid #888',
              borderRadius: '4px',
              width: '1.5em',
              height: '1.5em',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
              color: '#fff'
            }}
          >
            {isProcessed ? '✓' : ''}
          </button>
        </td>
      
    </tr>
  )

}

export default DocsRow